export const profileOptions = [
    "msgStudent",
    "msgProfessor",
    "msgCompany",
    "msgCenter",
    "msgOther",
]

export const countries = [
    "msgAfghanistan",
    "msgAlbania",
    "msgAlgeria",
    "msgAmericanSamoa",
    "msgAndorra",
    "msgAngola",
    "msgAnguilla",
    "msgAntarctica",
    "msgAntiguaandBarbuda",
    "msgArgentina",
    "msgArmenia",
    "msgAruba",
    "msgAustralia",
    "msgAustria",
    "msgAzerbaijan",
    "msgBahamas",
    "msgBahrain",
    "msgBangladesh",
    "msgBarbados",
    "msgBelarus",
    "msgBelgium",
    "msgBelize",
    "msgBenin",
    "msgBermuda",
    "msgBhutan",
    "msgBolivia",
    "msgBosniaHerzegovina",
    "msgBotswana",
    "msgBouvetIsland",
    "msgBrazil",
    "msgBritishIndian",
    "msgBrunei",
    "msgBulgaria",
    "msgBurkinaFaso",
    "msgBurundi",
    "msgCambodia",
    "msgCameroon",
    "msgCanada",
    "msgCapeVerde",
    "msgCaymanIslands",
    "msgCentralAfrican",
    "msgChad",
    "msgChile",
    "msgChina",
    "msgChristmasIsland",
    "msgCocosIslands",
    "msgColombia",
    "msgComoros",
    "msgCongo",
    "msgRepublicCongo",
    "msgCookIslands",
    "msgCostaRica",
    "msgIvoryCoast",
    "msgCroatia",
    "msgCuba",
    "msgCyprus",
    "msgCzechRepublic",
    "msgDenmark",
    "msgDjibouti",
    "msgDominica",
    "msgDominicanRepublic",
    "msgEastTimor",
    "msgEcuador",
    "msgEgypt",
    "msgEngland",
    "msgElSalvador",
    "msgEquatorialGuinea",
    "msgEritrea",
    "msgEstonia",
    "msgEthiopia",
    "msgFalklandIslands",
    "msgFaroeIslands",
    "msgFijiIslands",
    "msgFinland",
    "msgFrance",
    "msgFrenchGuiana",
    "msgFrenchPolynesia",
    "msgFrenchSouthern",
    "msgGabon",
    "msgGambia",
    "msgGeorgia",
    "msgGermany",
    "msgGhana",
    "msgGibraltar",
    "msgGreece",
    "msgGreenland",
    "msgGrenada",
    "msgGuadeloupe",
    "msgGuam",
    "msgGuatemala",
    "msgGuernsey",
    "msgGuinea",
    "msgGuineaBissau",
    "msgGuyana",
    "msgHaiti",
    "msgHeardMcDonald",
    "msgHolySee",
    "msgHonduras",
    "msgHongKong",
    "msgHungary",
    "msgIceland",
    "msgIndia",
    "msgIndonesia",
    "msgIran",
    "msgIraq",
    "msgIreland",
    "msgIsrael",
    "msgIsleMan",
    "msgItaly",
    "msgJamaica",
    "msgJapan",
    "msgJersey",
    "msgJordan",
    "msgKazakhstan",
    "msgKenya",
    "msgKiribati",
    "msgKuwait",
    "msgKyrgyzstan",
    "msgLaos",
    "msgLatvia",
    "msgLebanon",
    "msgLesotho",
    "msgLiberia",
    "msgLibyanJamahiriya",
    "msgLiechtenstein",
    "msgLithuania",
    "msgLuxembourg",
    "msgMacao",
    "msgNorthMacedonia",
    "msgMadagascar",
    "msgMalawi",
    "msgMalaysia",
    "msgMaldives",
    "msgMali",
    "msgMalta",
    "msgMarshallIslands",
    "msgMartinique",
    "msgMauritania",
    "msgMauritius",
    "msgMayotte",
    "msgMexico",
    "msgMicronesia",
    "msgMoldova",
    "msgMonaco",
    "msgMongolia",
    "msgMontserrat",
    "msgMontenegro",
    "msgMorocco",
    "msgMozambique",
    "msgMyanmar",
    "msgNamibia",
    "msgNauru",
    "msgNepal",
    "msgNetherlands",
    "msgNetherlandsAntilles",
    "msgNewCaledonia",
    "msgNewZealand",
    "msgNicaragua",
    "msgNiger",
    "msgNigeria",
    "msgNiue",
    "msgNorfolkIsland",
    "msgNorthKorea",
    "msgNorthernIreland",
    "msgNorthernMariana",
    "msgNorway",
    "msgOman",
    "msgPakistan",
    "msgPalau",
    "msgPalestine",
    "msgPanama",
    "msgPapua",
    "msgParaguay",
    "msgPeru",
    "msgPhilippines",
    "msgPitcairn",
    "msgPoland",
    "msgPortugal",
    "msgPuertoRico",
    "msgQatar",
    "msgReunion",
    "msgRomania",
    "msgRussianFederation",
    "msgRwanda",
    "msgSaintHelena",
    "msgSaintKitts",
    "msgSaintLucia",
    "msgSaintPierre",
    "msgSaintVincent",
    "msgSamoa",
    "msgSanMarino",
    "msgSaoTome",
    "msgSaudiArabia",
    "msgScotland",
    "msgSenegal",
    "msgSerbia",
    "msgSeychelles",
    "msgSierraLeone",
    "msgSingapore",
    "msgSlovakia",
    "msgSlovenia",
    "msgSolomonIslands",
    "msgSomalia",
    "msgSouthAfrica",
    "msgSouthSandwich",
    "msgSouthKorea",
    "msgSouthSudan",
    "msgSpain",
    "msgSriLanka",
    "msgSudan",
    "msgSuriname",
    "msgSvalbardJan",
    "msgSwaziland",
    "msgSweden",
    "msgSwitzerland",
    "msgSyria",
    "msgTajikistan",
    "msgTanzania",
    "msgThailand",
    "msgTimorLeste",
    "msgTogo",
    "msgTokelau",
    "msgTonga",
    "msgTrinidadTobago",
    "msgTunisia",
    "msgTurkey",
    "msgTurkmenistan",
    "msgTurksCaicos",
    "msgTuvalu",
    "msgUganda",
    "msgUkraine",
    "msgUnitedArabEmirates",
    "msgUnitedKingdom",
    "msgUnitedStates",
    "msgUnitedStatesMinor",
    "msgUruguay",
    "msgUzbekistan",
    "msgVanuatu",
    "msgVenezuela",
    "msgVietnam",
    "msgVirginIslandsBritish",
    "msgVirginIslandsUS",
    "msgWales",
    "msgWallisFutuna",
    "msgWesternSahara",
    "msgYemen",
    "msgZambia",
    "msgZimbabwe",
]