import { mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircle, mdiClockOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Fragment } from 'react';
import { Container, ContainerFlex, Card, Button, TextLocalized } from '../../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { LanguageContext } from '../../providers/LanguageProvider';
import MessageItem from './MessageItem';

const ThreadDetail = () => {
    const [item, setItem] = useState();

    const [isSending, setIsSending] = useState(false);
    const [formData, setFormData] = useState("");
    const [messages, setMessages] = useState([])
    const languageContext = useContext(LanguageContext);

    let history = useHistory();
    let params = useParams();

    useEffect(() => {
        HttpConnection(`${HttpOperations.threads}/${params.id}`, result => {
            setItem(result)
            setMessages([...result.messages]);
        }, error => console.log(error), HttpMethod.get);
    }, [params.id])

    const getItem = () => HttpConnection(`${HttpOperations.threads}/${params.id}`, result => {
        setItem(result)
        setMessages([...result.messages]);
    }, error => console.log(error), HttpMethod.get);

    const pushMessage = () => {
        if (!isSending) {
            setIsSending(true)
            HttpConnection(`${HttpOperations.threads}/${params.id}/message`,
                result => {
                    getItem();
                    setFormData("");
                    setIsSending(false);
                },
                error => console.log(error), HttpMethod.post, { text: formData });
        }
    }
    
    return (
        <Container width="12" justify="center" minheight="100vh" color="colorgray">
            <ContainerFlex width="12" px="16" justify="center">
                <ContainerFlex width="12" lg="8" pv="8">
                    <ContainerFlex width="12" pv="8" align="flex-end">
                        <Container width="wrap" fontColor="color-blue-dark" fontWeight="300" fontSize="48px" children={item ? item.title : ""} />
                        <Button onclick={() => history.goBack()} pv="4" ph="16" mh="8" mb="16" fontWeight="300" color="colorblack" hoverColor="colorprimary" fontColor="colorwhite" children={<TextLocalized children="actionBack" />} />
                    </ContainerFlex>
                    {
                        item ?
                            <Fragment>
                                <ContainerFlex width="12" justify="space-between" align="center" pb="16" fontSize="14px">
                                    <Container width="12" mb="16" fontWeight="100" children={item.text} />

                                    <ContainerFlex align="center">
                                        <Card flex pv="4" ph="8" radius="20px" align="center" border="solid" borderWidth="2px" borderColor="colorbluelight" fontColor="colorwhite" color="colorbluelight">
                                            <Icon style={{ margin: "0 4px 0 0" }} path={item.state.id === 1 ? mdiCheckboxBlankCircleOutline : mdiCheckboxMarkedCircle} size={1} />
                                            <TextLocalized children={item.state.id === 1 ? "msgOpen" : "msgClose"} />
                                        </Card>
                                        <Container mh="8" fontWeight="100" children={`${item.owner.name} ${item.owner.lastname}`} />
                                        <Icon style={{ margin: "0 8px" }} path={mdiClockOutline} size={"12px"} />
                                        <Container mh="8" fontWeight="100" children={format(new Date(item.date_create), "dd/MM/yyyy")} />
                                    </ContainerFlex>
                                </ContainerFlex>

                                <Container width="12" height="1px" color="color-blue-dark" />
                                <ContainerFlex width="12" pv="16" children={messages.map((item, index) => <MessageItem key={index} item={item} />)} />
                                {
                                    item.state.id === 1 ?
                                        <Fragment>
                                            <Container width="12" height="1px" color="colorblack" />

                                            <Card width="12" mt="24" border="solid" color="colorwhite" borderColor="colorbluelight" fontColor="color-blue-dark" borderWidth="1px">
                                                <textarea onChange={e => setFormData(e.target.value)} value={formData} placeholder={languageContext.dictionary["msgLeaveComment"]} rows="5" style={{ padding: "16px", borderRadius: "10px" }} />
                                            </Card>

                                            <ContainerFlex width="12" justify="flex-end" pb="16" fontSize="14px">
                                                <Button onclick={pushMessage} minwidth="300px" textAlign="center" pv="16" ph="32" mv="16" fontSize="18px" fontWeight="300" color="colorprimarylight" hoverColor="color-blue-dark" fontColor="colorwhite" radius="30px" children={<TextLocalized children="actionComment" />} />
                                            </ContainerFlex>
                                        </Fragment>
                                        : null
                                }
                            </Fragment>
                            : null
                    }
                </ContainerFlex>
            </ContainerFlex>
        </Container>
    );
};

export default ThreadDetail;