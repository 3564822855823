import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { LanguageContext } from '../../providers/LanguageProvider';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { InputWrapper } from '../forms/ContactFormView';
import ThreadItem from './ThreadItem';

import img_home from '../../../images/img/background-home.jpg';

const ThreadModal = ({ successCallback }) => {
    const [formData, setFormData] = useState({ title: "", text: "" });
    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.title && formData.text)
            HttpConnection(HttpOperations.threads, result => {
                dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                successCallback();
            }, error => console.log(error), HttpMethod.post, formData);
    }

    const close = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });

    return <Card onClick={e => e.stopPropagation()} shadowOn width="10" lg="4" px="16" color="colorgray" radius="12px">
        <Container width="12" mb="32" textAlign="center" fontColor="color-blue-dark" fontWeight="300" fontSize="38px" children={<TextLocalized children="actionNewThread" />} />
        <InputWrapper children={<input name="title" type="text" value={formData.title} onChange={onChange} placeholder={`${dictionary["msgTitle"]}`} />} />
        <Card width="12" mt="24" border="solid" color="colorwhite" borderColor="colorgray" fontColor="color-blue-dark" borderWidth="1px">
            <textarea onChange={onChange} name="text" value={formData.text} placeholder={dictionary["msgWriteText"]} rows="5" style={{ padding: "16px", borderRadius: "10px" }} />
        </Card>

        <Button onclick={sendRequest} px="16" mt="32" mb="16" textAlign="center" fontWeight="300" color="colorprimary" hoverColor="color-blue-dark" fontColor="colorwhite" radius="30px" children={<TextLocalized children="actionSend" />} />
        <Button onclick={close} px="16" mt="16" mb="16" textAlign="center" fontWeight="300" color="colorblack" hoverColor="color-blue-dark" fontColor="colorwhite" radius="30px" children={<TextLocalized children="actionClose" />} />

    </Card>
}

const Forum = () => {
    const [threads, setThreads] = useState([]);
    const { dispatch } = useContext(UserContext);

    let history = useHistory();

    useEffect(() => {
        getItems()
    }, [])

    const getItems = () => HttpConnection(`${HttpOperations.threads}/state/1`, result => setThreads(result), error => console.log(error), HttpMethod.get);
    const showItem = (item) => history.push(`/forum/${item.id}`);

    const showModal = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ThreadModal successCallback={getItems} /> } })
    }

    return (
        <Container width="12" justify="center" minheight="100vh" color="colorgray">
            <ContainerFlex width="12" justify="center" >
                <Container width="12" image={img_home}>
                    <ContainerFlex width="12" color="colorgrayalpha" pv="100" justify="center" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                        <Container width="12" children={<TextLocalized children="msgThreads" />} />
                        <Button onclick={showModal} mx="16" ph="64" pv="16"
                            color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal"
                            children={<TextLocalized children="actionNewThread" />} />
                    </ContainerFlex>
                </Container>

                <ContainerFlex width="12" lg="8" mt="32" children={threads.map((item, index) => <ThreadItem key={index} item={item} showItem={showItem} />)} />
            </ContainerFlex>
        </Container>
    );
};

export default Forum;