import React from 'react';
import { Container, ContainerFlex, TextLocalized, Button } from '../styled/Styled';

import logo_ue from '../../images/img/logo_ue_min.png'
import logo_intride_white from '../../images/img/logo_intride_white.png'

import { mdiEmail, mdiEmailNewsletter, mdiLinkedin } from '@mdi/js';
import Icon from '@mdi/react';
import { useHistory } from 'react-router-dom';

export const Footer = () => {

    const history = useHistory();

    return <Container>
        <ContainerFlex display="none" px="60" color="colorgreen" justify="space-between"
            align="center" fontSize="30px" fontWeight="bold" fontColor="colorwhite">
            <TextLocalized children="msgFooterSup" />

            <Button onclick={() => history.push("../contact")} shadow={false} mx="16" ph="64" pv="16"
                radius="50px" fontSize="17px" fontWeight="normal" fontColor="colorgreen"
                border="solid" borderWidth="2px" borderColor="colorwhite" color="colorwhite"
                textAlign="center">
                <TextLocalized children="msgBtnTwo" />
            </Button>
        </ContainerFlex>

        <ContainerFlex pt="40" justify="center" align="center" color="colorblack" fontColor="colorwhite" fontSize="12px">


            <Container textAlign="center" justify="center" ph="32" width="12" lg="3">
                <Container children={<TextLocalized children="msgProyectOwner" />} mb="16" />
                <Button shadow={false} onclick={() => window.open("https://intride.eu/", '_blank')}>
                    <img width="200" alt="logo_peu" src={logo_intride_white} />
                </Button>
            </Container>

            <ContainerFlex px="32" justify="center" width="12" lg="6">

                <Container mh="8" textAlign="center">
                    <TextLocalized children="msgFooterContact" />
                    <ContainerFlex justify="center" mv="16">
                        <Button onclick={() => history.push("../contact")} border="solid" borderWidth="2px" borderColor="colorwhite" px="8" radius="50%" maxheight="68px" mh="16">
                            <Icon path={mdiEmail} color="white" size={2} />
                        </Button>
                    </ContainerFlex>
                </Container>

                <Container mh="8" textAlign="center">
                    <TextLocalized children="msgFooterNews" />
                    <ContainerFlex justify="center" mv="16">
                        <Button onclick={() => window.open("https://intride.eu/subscibe-to-our-newsletter/", '_blank')} border="solid" borderWidth="2px" borderColor="colorwhite" px="8" radius="50%" maxheight="68px" mh="16">
                            <Icon path={mdiEmailNewsletter} color="white" size={2} />
                        </Button>
                    </ContainerFlex>
                </Container>

                <Container mh="8" textAlign="center">
                    <TextLocalized children="msgFooterLinkedIn" />
                    <ContainerFlex justify="center" mv="16">
                        <Button onclick={() => window.open("https://www.linkedin.com/groups/13846954/", '_blank')} border="solid" borderWidth="2px" borderColor="colorwhite" px="8" radius="50%" maxheight="68px" mh="16">
                            <Icon path={mdiLinkedin} color="white" size={2} />
                        </Button>
                    </ContainerFlex>
                </Container>

            </ContainerFlex>

            <ContainerFlex align="center" justify="center" width="12" lg="3" pv="10" ph="32" color="colorblack">
                <Container width="6" lg="12" px="8" children={<img width="100%" alt="img" src={logo_ue} />} />
                <Container width="6" lg="12" px="8" align="center" textColor="colorwhite">
                    <TextLocalized children="msgFooterEu" />
                    <Container width="12" pv="8" children={<TextLocalized children="msgFooterGr" />} />
                </Container>
            </ContainerFlex>

            <Container minheight="1px" width="12" color="colorwhite" />

            <ContainerFlex ph="32" pv="16" width="12" lg="12" textAlign="center" align="center">
                <Container width="auto" fontWeight="bold" children={<TextLocalized children="msgCopyright" />} />

                <Button onclick={() => window.open("https://www.cenfim.org/en/legal-notice", '_blank')} shadow={false} mh="16" mv="8" children={<TextLocalized children="msgFooterLegal" />} />
                <Button onclick={() => window.open("https://www.cenfim.org/en/privacy-policy", '_blank')} shadow={false} mh="16" mv="8" children={<TextLocalized children="msgFooterPrivacy" />} />
                <Button onclick={() => window.open("https://www.cenfim.org/en/cookie-policy", '_blank')} shadow={false} mh="16" mv="8" children={<TextLocalized children="msgFooterCookies" />} />
            </ContainerFlex>
        </ContainerFlex>


    </Container>

}
