import React, { useContext } from 'react';
import { ContainerFlex, Card, Container, Button, TextLocalized } from '../../styled/Styled';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import { imageURL } from '../../util/HttpConnection';
import { format, parse } from 'date-fns';
import { UserContext } from '../../providers/UserProvider';

const RoundLeftImg = styled.img`
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
`;

export const TrainingResourceItemView = ({ item }) => {
    const { state } = useContext(UserContext);
    const history = useHistory();

    const openDocument = () => {
        if (state.login) {
            window.open(item.link, "_blank")
        } else {
            history.push("/login")
        }
    }

    return <ContainerFlex width="6" px="16" minheight="100%">
        <Card flex shadow={false} align="stretch" minheight="100%" width="12" border="solid" borderWidth="1px" borderColor="colorgraydark" color="colorwhite">

            <Container width="3">
                <RoundLeftImg src={`${imageURL}${item.image}` ?? ""} width="100%" height="100%" style={{ objectFit: "cover" }} />
            </Container>

            <ContainerFlex width="9" ph="16" direction="column" justify="space-between">
                <Container>
                    <Container fontWeight="bold" fontSize="16px" mt="16" children={item.title} />
                    <Container fontSize="12px" fontColor="colorgrayalpha" children={format(new Date(item.date_create), "dd/MM/yyyy")} />

                    <Container fontSize="14px" mt="16" children={item.description} />
                </Container>

                <ContainerFlex mv="16" justify="space-between">
                    <Button flex color="colorblack" fontSize="16px" px="8" onclick={openDocument} align="center" fontColor="colorwhite">
                        <TextLocalized children="msgDownloadDoc" />
                    </Button>
                </ContainerFlex>
            </ContainerFlex>
        </Card>
    </ContainerFlex>
}

const TrainingResourceView = ({ items }) => {
    return (
        <ContainerFlex width="8" px="32" align="stretch">
            {items.map((item, index) => <TrainingResourceItemView key={index} item={item} />)}
        </ContainerFlex>
    );
};

export default TrainingResourceView;