import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { LanguageContext } from '../providers/LanguageProvider';
import { UserAction, UserContext } from '../providers/UserProvider';
import { Button, Card, ContainerFlex, TextLocalized } from '../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../util/HttpConnection';
import { InputWrapper } from './forms/ContactFormView';

import name from '../../images/img/intride_challenge_logo_black.png'
import home from '../../images/img/background-home.jpg';

const Login = () => {

    const [formData, setFormData] = useState({ email: "", password: "" });
    const { dispatch } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    let history = useHistory();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.email !== "" && formData.password !== "") {
            HttpConnection(HttpOperations.login, response => {
                dispatch({ action: UserAction.login, data: response });
                history.replace("/");
            }, error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorred", message: "errorLogin" } }), HttpMethod.post, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorred", message: "errorOblData" } });
        }
    }

    return (
        <ContainerFlex width="12" justify="center" height="calc(100vh - 64px)" image={home}>
            <ContainerFlex width="12" height="100%" pv="32" justify="center" align="center" color="colorgray-a50" >
                <ContainerFlex width="12" lg="8" height="100%" justify="center" align="center" >
                    <Card width="12" lg="6" justify="center" color="colorwhite" px="16" radius="20px">
                        <ContainerFlex width="12" mb="16" justify="center" align="center" children={<img alt="logo" src={name} height="64px" />} />
                        <ContainerFlex width="12" justify="center" align="center" children={<TextLocalized children="msgWelcome" />} />

                        <InputWrapper children={<input name="email" value={formData.email} onChange={onChange} placeholder={`${dictionary["msgEmail"]}*`} />} />
                        <InputWrapper children={<input type="password" name="password" value={formData.password} onChange={onChange} placeholder={`${dictionary["msgPassword"]}*`} />} />

                        <Button onclick={sendRequest} textAlign="center" px="12" mt="48" fontWeight="300" color="colorprimarylight" hoverColor="color-blue-dark" fontColor="colorwhite" radius="30px" children={<TextLocalized children="actionLogin" />} />
                        <Button onclick={() => history.push("register")} shadow={false} mv="24" textAlign="center" fontColor="colorblack" fontWeight="300" fontSize="12px" children={<TextLocalized children="msgCreateAccount" />} />
                    </Card>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Login;