import React from 'react';
import { ContainerFlex, Card, Container } from '../../styled/Styled';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import { imageURL } from '../../util/HttpConnection';

const RoundLeftImg = styled.img`
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
`;

export const PrototipaLabItemView = ({ item }) => {
    const history = useHistory();

    return <ContainerFlex width="6" px="16" minheight="100%">
        <Card flex={true} shadow={false} align="stretch" minheight="100%" width="12" border="solid" borderWidth="1px" borderColor="colorgraydark" color="colorwhite" onclick={() => history.push({ pathname: `/prototipalab/${item.id }`})} cursor="pointer">

            <Container width="3">
                <RoundLeftImg src={`${imageURL}${item.image}` ?? ""} width="100%" height="100%" style={{ objectFit: "cover" }} />
            </Container>

            <Container width="9" ph="16">
                <Container fontWeight="bold" fontSize="16px" mt="16" children={item.title} />
                <Container fontSize="12px" fontColor="colorgrayalpha" children="26/07/2020" />

                <Container fontSize="14px" mt="16" children={item.short_description} />

                <ContainerFlex mv="16" justify="space-between" align="stretch">
                    <ContainerFlex alignContent="center" justify="center" fontWeight="bold" fontSize="14px" fontColor="colorgrayalpha" children={item.author} />
                </ContainerFlex>
            </Container>
        </Card>
    </ContainerFlex>
}

const PrototipaLabView = ({ items }) => {
    return (
        <ContainerFlex width="8" px="32" align="stretch">
            {items.map((item, index) => <PrototipaLabItemView key={index} item={item} />)}
        </ContainerFlex>
    );
};

export default PrototipaLabView;