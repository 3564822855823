import React from 'react';
import { ContainerFlex, Card, Container } from '../../styled/Styled';

import Icon from '@mdi/react'
import { mdiTimerOutline } from '@mdi/js'
import { useHistory } from 'react-router-dom';
import { differenceInDays } from 'date-fns';
import { imageURL } from '../../util/HttpConnection';

const AudienceItemView = ({ item }) => <Card shadow={false} mv="4" ph="8" pv="4" borderColor="colorprimary" borderWidth="1px" border="solid" fontColor="colorprimary" fontWeight="600" fontSize="12px" radius="20px" children={item.name} />
const TeamItemView = ({ item }) => <Card shadow={false} mv="4" ph="8" pv="4" borderColor="colorbluelight" borderWidth="1px" border="solid" fontColor="colorbluelight" fontWeight="600" fontSize="12px" radius="20px" children={item.name} />
const TopicItemView = ({ item }) => <Card shadow={false} mv="4" ph="8" pv="4" borderColor={"colorgreen"} borderWidth="1px" border="solid" fontColor="colorgreen" fontWeight="600" fontSize="12px" radius="20px" children={item.name} />

const ChallengeItemView = ({ item }) => {
    const history = useHistory();


    const getDays = () => {
        let days = differenceInDays(new Date(item.phases[0].end_date.replace(" ", "T")), new Date());
        return days < 0 ? "EXPIRED" : `${days} days left`;
    }

    return <ContainerFlex width="12" lg="6" xl="4" px="16" minheight="100%">
        <Card minheight="100%" flex={true} direction="column" shadow={false} width="12" border="solid" borderWidth="1px" borderColor="colorgraydark" onclick={() => history.push(`/challenge/${item.id}`)} cursor="pointer">
            <Container width="12">
                <Card shadow={false} height="200px" image={`${imageURL}${item.image}`} radiusbl="0" radiusbr="0" />
            </Container>

            <ContainerFlex width="12" ph="16" pt="8" flexMode="height" direction="column" justify="space-between">
                <Container>
                    <Container fontWeight="bold" fontSize="14px" fontColor="colorgrayalpha" mv="8" children={item.entity.name} />
                    <Container fontWeight="bold" fontSize="16px" mv="8" children={item.title} />
                    <Container fontSize="14px" mv="8" textAlign="justify" children={item.short_description} />
                </Container>

                <ContainerFlex width="12" pt="4" pb="16" justify="space-around" align="center">
                    {item.audience.map(audience => <AudienceItemView key={audience.id} item={audience} />)}
                    {item.team.map(team => <TeamItemView key={team.id} item={team} />)}
                    {item.topic.map(topic => <TopicItemView key={topic.id} item={topic} />)}
                </ContainerFlex>
            </ContainerFlex>

            <Card shadow={false} flex={true} radiustl="0" radiustr="0" align="center" width="12" pv="16" justify="center" color="colorgraybox" fontColor="colorgrayalpha90" fontWeight="bold">
                <Icon path={mdiTimerOutline} size={1} />
                <Container ml="8" fontSize="12px" children={getDays()} />
            </Card>
        </Card>
    </ContainerFlex>
}

const ChallengeView = ({ items }) => {
    return (
        <ContainerFlex width="12" sm="9" px="32" align="stretch">
            {items.map((item, index) => <ChallengeItemView key={index} item={item} />)}
        </ContainerFlex>
    );
};

export default ChallengeView;