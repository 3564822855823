import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ContainerFlex, Button, Container, TextLocalized } from '../styled/Styled';
import Icon from '@mdi/react'
import { mdiLightbulbOn, mdiDraw, mdiFolderMultiple } from '@mdi/js'
import { UserAction, UserContext } from '../providers/UserProvider';

const MenuItem = ({ icon, name, link, fontColor, fontSize }) => {
    return <Button flex shadow={false} ph="8" mh="8" fontColor={fontColor} fontSize={fontSize} align="center" onclick={link}>
        <Container display="none" cursor="pointer" ph="8" children={<Icon path={icon} size={"20px"} />} />
        <TextLocalized children={name} />
    </Button>
}

const MenuView = ({ index }) => {

    const { dispatch, state } = useContext(UserContext);

    let history = useHistory();

    const _getFontColor = (tab) => index === tab ? "colorgrayalpha" : "colorwhite";
    const _getFontSize = (tab) => index === tab ? "12px" : "16px";

    const logout = () => dispatch({ action: UserAction.logout });

    return <ContainerFlex justify="center" align="center">

        <MenuItem icon={mdiLightbulbOn} name="msgCat1" link={() => history.push("/challenges")} fontColor={_getFontColor(1)} fontSize={_getFontSize(1)} />
        <MenuItem icon={mdiFolderMultiple} name="msgCat3" link={() => history.push("/prototipalabs")} fontColor={_getFontColor(3)} fontSize={_getFontSize(3)} />
        <MenuItem icon={mdiDraw} name="msgCat4" link={() => history.push("/investigation")} fontColor={_getFontColor(2)} fontSize={_getFontSize(2)} />
        <MenuItem icon={mdiLightbulbOn} name="msgCat5" link={() => history.push("/training")} fontColor={_getFontColor(5)} fontSize={_getFontSize(5)} />
        <MenuItem icon={mdiLightbulbOn} name="msgCat6" link={() => history.push("/forum")} fontColor={_getFontColor(6)} fontSize={_getFontSize(6)} />
        {
            !state.login ?
                <Fragment>
                    <Button radius="30px" flex color="colorprimarylight" fontSize="16px" mr="8" pv="8" ph="16" onclick={() => history.push("/login")} align="center" fontColor="colorwhite">
                        <TextLocalized children="actionLogin" />
                    </Button>

                    <Button radius="30px" flex color="colorblack" fontSize="16px" pv="8" ph="16" onclick={() => window.open("https://community.intride.eu/dashboard/", "_blank")} align="center" fontColor="colorwhite">
                        <TextLocalized children="actionBusinessAccess" />
                    </Button>
                </Fragment>
                :
                <Button radius="30px" flex color="colorprimarylight" fontSize="16px" mr="8" pv="8" ph="16" onclick={logout} align="center" fontColor="colorwhite">
                    <TextLocalized children="actionLogout" />
                </Button>
        }
    </ContainerFlex>;
};

export default MenuView;