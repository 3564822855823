import Icon from '@mdi/react';
import { mdiClockOutline, mdiCommentOutline } from '@mdi/js';

import React from 'react';
import { Button, Container, ContainerFlex } from '../../styled/Styled';
import { format } from 'date-fns';

const ThreadItem = ({ item, showItem }) => {
    return <Container width="12" lg="6" px="8">
        <Button onclick={() => showItem(item)} flex width="12" px="8" align="flex-start" border="solid" borderColor="colorbluelight" borderWidth="1px" color="colorwhite">
            <Container ph="8" width="wrap">
                <Container fontSize="18px" fontWeight="300" children={item.title} />
                <ContainerFlex mt="4" align="center">
                    <Container fontSize="10px" fontWeight="100" children={item.owner.name} />
                    <Icon style={{ margin: "0 8px" }} path={mdiClockOutline} size={"12px"} />
                    <Container fontSize="10px" fontWeight="100" children={format(new Date(item.date_create), "dd/MM/yyyy")} />
                </ContainerFlex>
            </Container>
            <ContainerFlex align="center">
                <Icon style={{ margin: "0 8px" }} path={mdiCommentOutline} size={1} />
                <Container fontSize="12px" children={item.num_messages} />
            </ContainerFlex>
        </Button>
    </Container>;
};

export default ThreadItem;