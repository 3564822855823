import React, { useState, useEffect } from 'react';
import { Container, ContainerFlex, TextLocalized } from '../../styled/Styled';
import img_home from '../../../images/img/background-home.jpg';
import PrototipaLabView from './PrototipaLabView';
import { HttpConnection, HttpOperations } from '../../util/HttpConnection';

const PrototipaLabFilterView = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        HttpConnection(HttpOperations.prototipaLabs,
            item => setItems(item),
            error => console.log(error));
    }, [])

    return (
        <Container color="colorgray" extras={"full-height"}>
            <Container width="12" image={img_home}>
                <ContainerFlex width="12" color="colorgrayalpha" pv="100" justify="center" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                    <Container width="12" children={<TextLocalized children="msgCat3" />} />
                    <ContainerFlex width="8" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgCat3Sub" />} />
                </ContainerFlex>
            </Container>

            <ContainerFlex width="12" justify="center">
                <PrototipaLabView items={items} />
            </ContainerFlex>
        </Container>
    );
};

export default PrototipaLabFilterView;