import React, { useState, useRef, useEffect } from 'react';
import { Container, ContainerFlex, Button } from '../../styled/Styled';
import img_home from '../../../images/img/background-home.jpg';
import { useHistory, useParams } from 'react-router-dom';
import { HttpConnection, HttpOperations, imageURL } from '../../util/HttpConnection';

const InvestigationResourceItem = ({ item }) => {
    const [height, setHeight] = useState();
    useEffect(() => {
        setHeight(Math.round(Math.random() * (3 - 1) + 1));
    }, [])

    const _openLink = () => {
        let url = item.url.includes("http") || item.url.includes("https") ? item.url : `https://${item.url}`;
        window.open(url, '_blank');
    }

    return (
        <Button shadow={true} shVertical={true} color="colorwhite" onclick={_openLink} width="12" fontColor="colorprimary" fontWeight="bold" fontSize="22px" pl="16" mv="16" pt="8">
            <Container cursor="pointer" height={`${height * 150}px`} width="12" image={`${imageURL}${item.image}`} />
            <Container cursor="pointer" width="12" pv="8" children={item.title} />
            <Container fontSize="14px" fontWeight="normal" fontColor="colorblack" cursor="pointer" width="12" pt="8" pb="32" pr="32" children={item.description} />
        </Button>
    )
}

const InvestigationResourceView = () => {
    const [item, setItem] = useState({});
    const [columns, setColumns] = useState([[], [], []])
    const [isSticky, setIsSticky] = useState(false)

    const history = useHistory();
    const params = useParams();

    const stickyRef = useRef();

    useEffect(() => {
        //Sticky header
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(() => {
        const requestAPI = async () => {
            HttpConnection(`${HttpOperations.category}/${params.id}`,
                (item) => {
                    let columnIndex = 0
                    var myColumns = [[], [], []];
                    item.resources.forEach(element => {
                        myColumns[columnIndex].push(element);
                        columnIndex = columnIndex < 2 ? columnIndex + 1 : 0;
                    });
                    setItem(item);
                    setColumns(myColumns);
                }, error => console.log(error));
        }
        requestAPI();
    }, [params.id])

    const handleScroll = () => setIsSticky(stickyRef.current.getBoundingClientRect().top <= 0);

    return (
        <Container color="colorgray" justify="center" extras="full-height">
            <Container width="12" image={img_home}>
                <ContainerFlex width="12" color="colorgrayalpha" pv="100" justify="center" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center" children={item.name} />
            </Container>

            <Container myRef={stickyRef} position={isSticky ? "sticky" : null} top="0" elevation="2" width="12">
                <ContainerFlex width="12" color="colorwhite" pv="8" ph="32" justify="center" fontColor="colorprimary" align="center">
                    <Button shadow={false} pv="16" onclick={() => history.goBack()} children={`Innovation Resources / ${item.name ?? ""}`} />
                </ContainerFlex>
                <Container width="12" minheight="1px" color="colorgraydark" />
            </Container>

            <ContainerFlex width="12" justify="center">
                <ContainerFlex pv="64" width="12" lg="10" justify="center" >
                    {
                        columns.map((column, index) => {
                            return <Container key={index} width="12" md="6" lg="4" px="8">
                                {column.map((item, index) => <InvestigationResourceItem key={index} item={item} />)}
                            </Container>
                        })
                    }
                </ContainerFlex>
            </ContainerFlex>
        </Container>
    );
};

export default InvestigationResourceView;