import React, { Fragment, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom";

import './css/App.css';
import './css/bootstrap-grid.css';
import { Container, ContainerFlex, TextLocalized, Button } from './components/styled/Styled';
import Header from './components/view/Header';
import HomeView from './components/view/HomeView';
import { Footer } from './components/view/Footer';
import { LanguageProvider } from './components/providers/LanguageProvider';
import ChallengeDetailView from './components/view/challenge/ChallengeDetailView';
import ChallengeFilterView from './components/view/challenge/ChallengeFilterView';
import PrototipaLabFilterView from './components/view/protipalab/PrototipaLabFilterView';
import PrototipaLabDetailView from './components/view/protipalab/PrototipaLabDetailView';
import ContactView from './components/view/forms/ContactFormView';
import ParticipateView from './components/view/forms/ParticipateView';
import InvestigationView from './components/view/idresources/InvestigationView';
import InvestigationResourceView from './components/view/idresources/InvestigationResourceView';
import TrainingResourceFilterView from './components/view/training/TrainingResourceFilterView';
import Login from './components/view/Login';
import { UserContext, UserProvider } from './components/providers/UserProvider';
import Register from './components/view/Register';
import Forum from './components/view/forum/Forum';
import { NotificationView } from './components/util/NotificationUtil';
import ModalView from './components/util/ModalUtil';
import ThreadDetail from './components/view/forum/ThreadDetail';

const ScrollTop = ({ children }) => {
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo(0, 0));
    return () => unlisten();
  }, [history]);

  return children;
}

const SwitchView = () => {
  const [showCookies, setShowCookies] = useState(false)
  const { state } = useContext(UserContext);

  return <Container minheight="100vh">
    <Router basename="/">
      <ScrollTop>
        <Header />
        <Switch>
          <Route exact path="/" component={HomeView} />
          <Route exact path="/challenges" component={ChallengeFilterView} />
          <Route exact path="/challenge/:id" component={ChallengeDetailView} />
          <Route exact path="/prototipalabs" component={PrototipaLabFilterView} />
          <Route exact path="/prototipalab/:id" component={PrototipaLabDetailView} />
          <Route exact path="/investigation" component={InvestigationView} />
          <Route exact path="/resource/:id" component={InvestigationResourceView} />
          <Route exact path="/contact" component={ContactView} />
          <Route exact path="/participate/:id" component={ParticipateView} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          {
            state.login ?
              <Fragment>
                <Route exact path="/training" component={TrainingResourceFilterView} />
                <Route exact path="/forum" component={Forum} />
                <Route exact path="/forum/:id" component={ThreadDetail} />
              </Fragment>
              : null
          }
          <Redirect to={"/login"} />
        </Switch>
      </ScrollTop>
      <Footer />
    </Router>
    {
      showCookies ?
        <ContainerFlex position="fixed" bottom="0" px="32" color="colorwhite" width="12" elevation="3" align="center">
          <Container width="10">
            <TextLocalized children="msgCookies" />
          </Container>
          <Container fontColor="colorwhite" fontWeight="bold">
            <Button onclick={() => window.open("https://cenfim.org/es/politica-de-cookies", '_blank')} shadow={false} color="colorblack" px="16" mx="16" children={<TextLocalized children="msgFooterPrivacy" />} />
            <Button onclick={() => setShowCookies(false)} shadow={false} textAlign="center" color="colorblack" px="16" mx="16" children={<TextLocalized children="actionAccept" />} />
          </Container>
        </ContainerFlex>
        : null
    }

    {state.notification.show ? <NotificationView /> : null}
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Container>
}

const App = () => <LanguageProvider children={<UserProvider children={<SwitchView />} />} />;

export default App;
