import { UserData } from '../providers/UserProvider';

export const checkLoginUser = (user) => {
    var storageUser = getStorageUser();
    return storageUser && storageUser.id === user.id;
}

export const updateStorageUser = (data) => {
    var local = getStorageUser()
    data.password = local.password;
    localStorage.setItem(UserData.user, JSON.stringify(local));
}

export const saveStorageUser = (user) => {
    localStorage.setItem(UserData.user, JSON.stringify(user));
}

export const getStorageUser = () => {
    var encrypted = localStorage.getItem(UserData.user);
    if (encrypted) {
        try {
            var user = JSON.parse(encrypted)
            return user ? user : null
        } catch (error) {
            localStorage.removeItem(UserData.user)
            console.error("unknow user")
        }
    }
    return null;
}

export const getUserToken = () => {
    var storageUser = getStorageUser();
    return (storageUser) ? storageUser.token : "";
}

export const verifyEmailFormat = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}