import { getUserToken } from "./UserUtil"

export const HttpOperations = {
    login: "auth",
    user: "user",

    threads: "threads",
    message: "message",

    trainings: "trainings",

    category: "category",
    prototipaLabs: "protipalabs",
    challenges: "challenges",
    entity: "entity",
    participate: "participate",
    contact: "contact"
}

export const HttpMethod = {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    delete: "DELETE"
}

export const HttpConnection = async (operation, success, error, method = "GET", data = null, checkingAuth = false) => {

    let token = getUserToken();

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token === null ? '' : token}`,
        }
    })

    let item = Object()

    if (!request.ok) {
        error(request.status);
        console.log(request.status);
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item.item);
    }
}

export const HttpFormData = async (operation, success, error, data, files = [], checkingAuth = false) => {

    var formData = new FormData()
    files.forEach((file) => formData.append(file.key, file.file));
    formData.append("filesCount", files.filter(file => file.key !== "main").length);

    if (data)
        formData.append("json", JSON.stringify(data));

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: HttpMethod.post,
        body: formData,
    })

    let item = Object()

    if (!request.ok) {
        error(request.status);
    } else {
        try {
            item = await request.json();
            item = item.item;
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

const debugMode = false;

const serverUrlEndpoint = debugMode ? "http://192.168.5.38/cenfim/intride-challenge-api/public/api/" : "https://community.intride.eu/intride-challenge-api/public/api/";
export const imageURL = debugMode ? "http://192.168.5.38/cenfim/intride-challenge-api/public/images/" : "https://community.intride.eu/intride-challenge-api/public/images/";