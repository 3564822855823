import React, { useContext, useState } from 'react';
import { ContainerFlex, Container, TextLocalized, Card, Button } from '../../styled/Styled';
import { LanguageContext } from '../../providers/LanguageProvider';
import { contactDefault } from '../../util/ConstantUtil';
import { HttpOperations, HttpConnection, HttpMethod } from '../../util/HttpConnection';
import { UserAction, UserContext } from '../../providers/UserProvider';

export const InputWrapper = ({ children }) => <Card px="8" mv="16" width="12" color="colorwhite" children={children} />

const ContactView = () => {
    const [formData, setFormData] = useState({ ...contactDefault })
    const { dispatch } = useContext(UserContext);

    const languageContext = useContext(LanguageContext);
    const _onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendForm = () => {
        if (formData.company_name !== "" && formData.company_job !== "" && formData.contact_firstname !== "" && formData.contact_lastname !== "" &&
            formData.contact_email !== "" && formData.contact_phone !== "" && formData.reason !== "" && formData.message !== "") {

            HttpConnection(HttpOperations.contact,
                () => {
                    setFormData({ ...contactDefault });
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorgreen", message: "msgRegisterSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorred", message: "errorSave" } }),
                HttpMethod.post, formData)
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorred", message: "errorOblData" } });
        }
    }

    return (
        <ContainerFlex width="12" justify="center" pv="64" ph="32">
            <Container width="8" textAlign="center" fontWeight="bold" fontSize="32px" children={<TextLocalized children="msgContactFormTitle" />} />
            <Container mv="16" children={<TextLocalized children="msgContactFormSubtitle" />} />

            <ContainerFlex width="12" lg="8">

                <Container width="12" lg="6" px="32">
                    <InputWrapper children={<input name="contact_firstname" value={formData.contact_firstname} onChange={_onChange} placeholder={`${languageContext.dictionary["msgFirstname"]}*`} />} />
                    <InputWrapper children={<input name="contact_lastname" value={formData.contact_lastname} onChange={_onChange} placeholder={`${languageContext.dictionary["msgLastname"]}*`} />} />
                    <InputWrapper children={<input name="contact_email" value={formData.contact_email} onChange={_onChange} placeholder={`${languageContext.dictionary["msgEmail"]}*`} />} />
                    <InputWrapper children={<input name="contact_phone" value={formData.contact_phone} onChange={_onChange} placeholder={`${languageContext.dictionary["msgPhone"]}*`} />} />
                    <InputWrapper children={<input name="company_name" value={formData.company_name} onChange={_onChange} placeholder={`${languageContext.dictionary["msgCompanyName"]}*`} />} />
                    <InputWrapper children={<input name="company_job" value={formData.company_job} onChange={_onChange} placeholder={`${languageContext.dictionary["msgJobTitle"]}*`} />} />
                    <InputWrapper children={<input name="contact_linkedin" value={formData.contact_linkedin} onChange={_onChange} placeholder={`${languageContext.dictionary["msgLinkdin"]}`} />} />
                </Container>

                <Container width="12" lg="6" px="32">
                    <InputWrapper>
                        <select style={{ width: "100%" }} name="reason" value={formData.reason} onChange={_onChange}>
                            <option children={`${languageContext.dictionary["msgReasonContact"]}*`} />
                            <option children={languageContext.dictionary["msgProposeChallenge"]} />
                            <option children={languageContext.dictionary["msgProposeResource"]} />
                            <option children={languageContext.dictionary["msgProposeConcept"]} />
                            <option children={languageContext.dictionary["msgProposeOther"]} />
                        </select>
                    </InputWrapper>
                    <InputWrapper children={<textarea name="message" rows="10" value={formData.message} onChange={_onChange} placeholder={`${languageContext.dictionary["msgMessage"]}*`} />} />
                </Container>

            </ContainerFlex>

            <Container width="12" mv="16" textAlign="center" children={<TextLocalized children="msgTerms" />} />

            <ContainerFlex width="12" justify="center" align="center">
                <Button onclick={sendForm} shadow={false} mx="16" ph="64" pv="16" color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal" fontColor="colorwhite" textAlign="center">
                    <TextLocalized children="actionSubmit" />
                </Button>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ContactView;