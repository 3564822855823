import React from 'react';
import { Container, ContainerFlex, TextLocalized, Card, HoverContainer } from '../../styled/Styled';
import img_home from '../../../images/img/background-home.jpg';
import { useHistory } from 'react-router-dom';
import { demoDesignResources } from '../../../constantes/demoDesignResources';

const InvestigationItem = ({ item }) => {
    let history = useHistory();
    return (
        <Container width="4" px="16">
            <Card onclick={() => history.push({ pathname: `/resource/${item.id}` })} image={item.image} width="12" height="300px" fontColor="colorwhite" fontWeight="bold" fontSize="22px">
                <HoverContainer children={item.name} />
            </Card>
        </Container>
    )
}

const InvestigationView = () => {
    return (
        <ContainerFlex justify="center" extras={"full-height"}>
            <Container width="12" image={img_home}>
                <ContainerFlex width="12" color="colorgrayalpha" pv="100" justify="center" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                    <Container width="12" children={<TextLocalized children="msgCat4" />} />
                    <ContainerFlex width="8" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgCat4Sub" />} />
                </ContainerFlex>
            </Container>

            <ContainerFlex pv="64" width="12" lg="8" justify="center">
                {demoDesignResources.map((item, index) => <InvestigationItem key={index} item={item} />)}
            </ContainerFlex>

        </ContainerFlex>
    );
};

export default InvestigationView;