import img_tools from '../images/img/designresources/tools.png';
import img_public from '../images/img/designresources/publications.png';
import img_cowork from '../images/img/designresources/coworking.png';
import img_fablabs from '../images/img/designresources/fablabs.png';
import img_videos from '../images/img/designresources/videos.jpg';

export const demoDesignResources = [
    {
        id: 1,
        name: "Tools",
        image: img_tools,
        resources: [
            {
                id: 1,
                name: "MURAL",
                description: "MURAL is a visual collaboration workspace for modern teams. Based in the cloud, MURAL provides shared, digital whiteboards where teams visually explore complex challenges, facilitate design thinking methods, and organize Agile processes across any device.",
                image: "https://plsadaptive.s3.amazonaws.com/eco/images/partners/ED0M1OwPZcAuxvcifzZ1fcOao1LPsIj8pcsg41Bz.png",
                url: "https://www.mural.co/"
            },
            {
                id: 2,
                name: "75 Tools for Creative Thinking",
                description: "A fun card deck for anyone who needs creative inspiration.",
                image: "https://images-na.ssl-images-amazon.com/images/I/411XuGEWQFL._SX350_BO1,204,203,200_.jpg",
                url: "http://75toolsforcreativethinking.com/"
            },
            {
                id: 3,
                name: "Innovation Management Software by Strategyzer",
                description: "The official web-app from the makers of Business Model Generation & Value Proposition Design. ",
                image: "https://i.pinimg.com/originals/2a/42/62/2a42626f175c9483b758f97dbcad24b2.png",
                url: "https://www.strategyzer.com/app"
            },
        ]
    },
    {
        id: 2,
        name: "Publications",
        image: img_public,
        resources: [
            {
                id: 1,
                name: "The Field Guide to Human-Centered Design",
                description: "IDEO: A step-by-step guide that will get you solving problems like a designer.",
                image: "https://design-kit-staging.s3.amazonaws.com/resources/cover_images/000/000/001/original/Field_Guide.png?1428586973",
                url: "https://www.designkit.org/resources/1"
            },
            {
                id: 2,
                name: "Creative Confidence",
                description: "David Kelley & Tom Kelley: IDEO founder and Stanford d.school creator David Kelley and his brother Tom Kelley, IDEO partner and the author of the bestselling The Art of Innovation, have written a powerful and compelling book on unleashing the creativity that lies within each and every one of us.",
                image: "https://www.creativeconfidence.com/assets/images/pages/CCBook_Front_Comp_560px.jpg",
                url: "https://www.creativeconfidence.com/buy/"
            },
            {
                id: 3,
                name: "Value Proposition Design",
                description: "Strategyzer: Tired of endless text? Value Proposition Design simplifies complex ideas into quickly readable illustrations with only the most practical, important details. The result? You'll learn more, in less time, and have fun along the way.",
                image: "https://www.strategyzer.com/hs-fs/hubfs/Strategyzer_November2018%20Theme/images/value-proposition-design-book-c5786cb93c0fc2a7cd0ed9116718cda1a2e120121178254fce47dbd62ec56be3.png?width=1180&height=992&name=value-proposition-design-book-c5786cb93c0fc2a7cd0ed9116718cda1a2e120121178254fce47dbd62ec56be3.png",
                url: "https://www.strategyzer.com/books/value-proposition-design"
            },
            {
                id: 4,
                name: "Creativity, INC.",
                description: "Ed Catmull: Creativity, Inc. is a book for managers who want to lead their employees to new heights, a manual for anyone who strives for originality, and the first-ever, all-access trip into the nerve center of Pixar Animation—into the meetings, postmortems, and “Braintrust” sessions where some of the most successful films in history are made.",
                image: "https://www.creativityincbook.com/wp-content/themes/CreativityInc/images/cover-flat.jpg",
                url: "https://www.creativityincbook.com/about/"
            },
            {
                id: 5,
                name: "Austin Kleon collection:",
                description: "A list of bestselling books and guides to staying creative, sharing your creativity and getting discovered.",
                image: "https://austinkleon.com/wp-content/uploads/2018/08/9781523506644_3D-for-book-page-600x668.jpg",
                url: "https://austinkleon.com/books/"
            },
        ]
    },
    {
        id: 3,
        name: "Videos",
        image: img_videos,
        resources: [

            {
                id: 1,
                name: "Design matters",
                description: "Debbie Millman: The world's first podcast about design and an inquiry into the broader world of creative culture through wide-ranging conversations with designers, writers, artists, curators, musicians, and other luminaries of contemporary thought.",
                image: "https://static.wixstatic.com/media/843229_70b11eddefb941f6a2298d747d7f4ba8~mv2.jpg/v1/fill/w_288,h_268,al_c,q_80,usm_0.66_1.00_0.01/843229_70b11eddefb941f6a2298d747d7f4ba8~mv2.jpg",
                url: "https://www.designmattersmedia.com/designmatters"
            },
        ]
    },
    {
        id: 4,
        name: "Coworkings",
        image: img_cowork,
        resources: [
            {
                id: 1,
                name: "Makers of Barcelona (MOB)",
                description: "Do much more than cowork with MOB by joining our talent hub, community & giant family.",
                image: "https://viue.com/wp-content/uploads/2018/03/mob-barcelona.png",
                url: "https://mob-barcelona.com/"
            },
            {
                id: 2,
                name: "Aticco",
                description: "Coworking, custom-made offices, private offices, events, meetings, and training. Tell us what you need and we will find the space that fits your needs.",
                image: "https://aticco.com/wp-content/uploads/2020/03/Aticco_Madrid-1080x675.jpg",
                url: "https://aticco.com/"
            },
            {
                id: 3,
                name: "Betahaus",
                description: "Welcome to betahaus coworking Barcelona. We give entrepreneurs and creative professionals a home using the “perpetual beta” principle. Betahaus is open code, undone, incomplete and always changing.",
                image: "https://www.betahaus.es/wp-content/uploads/2020/03/betahaus-bcn-space-opencoworking-terraceview-detail-banner-1.jpg",
                url: "https://www.betahaus.es/"
            },
        ]
    },
    {
        id: 5,
        name: "FabLabs",
        image: img_fablabs,
        resources: [
            {
                id: 1,
                name: "Fab Lab Barcelona",
                description: "One of the leading laboratories of the worldwide network of Fab Labs",
                image: "https://www.fablabs.io/media/W1siZiIsIjIwMTkvMDMvMjcvMTAvMzEvNDEvMjViZTZkYTYtNWMwMi00MjJmLTk3NjQtMWViYTE1MWQyZWRjL21tLmpwZyJdLFsicCIsInRodW1iIiwiODAweCJdXQ/mm.jpg?sha=e3ec7d3244139158",
                url: "https://fablabbcn.org/"
            },
        ]
    },
]