const es = {
  msgHomeTitleOne: 'INTRIDE OPEN INNOVATION PLATFORM',
  msgHomeTitleTwo: "FOR SMART DESIGNERS",

  msgHomeSubTitle: "Open innovation platform where designers, researches, students, entrepreneurs, employees of manufacturing companies and other stakeholders work in a collaborative way to build real solutions for today's needs of manufacturing sector SMEs",
  msgBtnOne: "SEE CHALLENGES",
  msgBtnTwo: "PROPOSE A CHALLENGE",
  msgBtnThree: "TRAINING RESOURCES",

  msgCat1: "Challenges",
  msgCat1Sub: "Participate in our challenges, co-create ideas and solutions for real challenges and needs.",
  msgCat3: "Concepts and demos",
  msgCat3Sub: "Check out our collection of case studies, solutions, projects, concepts and demos.",
  msgCat4: "Design resources",
  msgCat4Sub: "A directory of essential design tools and resources to develop, refine and scale up your ideas.",
  msgCat5: "Training resources",
  msgCat5Sub: "Check out our collection of the training resources of the INTRIDE Joint Master Degree, entitled \"Strategic Design for Innovation in the manufacturing sector\"..",
  msgCat6: "Forum",
  msgCat6Sub: "A directory of essential design tools and resources to develop, refine and scale up your ideas.",

  msgUser1: "Students",
  msgUser2: "Designers",
  msgUser3: "Entrepreneurs",
  msgUser4: "Researchers",
  msgUser5: "Employees",
  msgUser6: "Stakeholders",

  msgHomeScOneTitle: "Current challenges",
  msgHomeScOneButton: "ALL CHALLENGES",

  msgHomeScFourTitle: "Training resources available",
  msgHomeScFourText: "Register and have access to the training resources of the INTRIDE Joint Master Degree, entitled \"Strategic Design for Innovation in the manufacturing sector\".",
  msgHomeScFourButton: "ALL TRAINING RESOURCES",

  msgHomeScTwoTitle: "Do you want to participate?",
  msgHomeScTwoSubtitle: "Do you want to contribute with your ideas and be part of this community? We are looking for motivated people like you",

  msgHomeScThreeTitle: "Who is behind the INTRIDE platform?",
  msgHomeScThreeSubtitle: "We help communities of innovators to grow and create by working on challenges",
  msgHomeScThreeButton: "CONTACT US",

  msgShareChallenge: "SHARE IT ON YOUR SOCIAL MEDIA",
  msgFooterSup: "Do you want to launch a challenge?",
  msgCopyright: "Copyright&copy;2022 INTRIDE. All rights reserved",

  actionParticipate: "Participate",

  msgAddittionalInfo: "ADDITIONAL INFORMATION",
  msgDownloadDoc: "Download document",

  msgProtipaSecThreeTitle: "Are you interested?",
  msgProtipaSecThreeButton: "Contact with us",

  msgFilterBy: "Filter by",
  msgSortBy: "Sort by",

  loremIpsum: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  msgProyectOwner: "A PROJECT OF",
  msgFooterContact: "CONTACT US",
  msgFooterNews: "OUR NEWSLETTER",
  msgFooterLegal: "Legal notice",
  msgFooterPrivacy: "Privacy",
  msgFooterCookies: "Cookie Policy",
  msgFooterCondic: "Condiciones generales del servicio",
  msgFooterCalidad: "Política de calidad",
  msgFooterEu: "The European Commission's support for the production of this publication does not constitute an endorsement of the contents, which reflect the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein.",
  msgFooterGr: "Grant Agreement: 612622-EPP-1-2019-1-IT-EPPKA2-KA",
  msgCookies: "Esta Web utiliza cookies de terceros de publicidad comportamental propiedad de Google, persistentes durante 2 años, para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. Si continúa navegando consideramos que está de acuerdo con su uso. Podrá revocar el consentimiento y obtener más información consultando nuestra Política de cookies.",
  msgFooterLinkedIn: 'LINKEDIN GROUP',

  actionAccept: "Accept",
  actionSubmit: "Submit",
  actionBusinessAccess: "Managers Access",
  actionSend: "Send",
  actionLogin: "Login",
  actionLogout: "Logout",
  actionRegister: "Sign in",
  actionNewThread: "ADD NEW THREAD",
  actionClose: "Close",

  msgContactTitle: "Get in touch with our open innovation experts",
  msgContactFormTitle: "Bridge the gap between higher education institutions and manufacturing industries and harness the potential of Open Innovation",
  msgContactFormSubtitle: "Submit your information and an Intride expert will follow up with you as soon as possible",

  msgEntityInfo: "Organization info",
  msgUserInfo: "User info",
  msgAccountInfo: "Account info",

  msgCif: "VAT number *",
  msgCountryObl: "Country *",
  msgName: "Name *",

  msgCreateAccount: "Already not register? Create account here",
  msgTitle: "Title",
  msgWriteText: "Write your text here...",
  msgFirstname: "First name",
  msgLastname: "Last name",
  msgJobTitle: "Job position",
  msgEmailObl: "Email *",
  msgPassObl: "Password *",
  msgLinkdin: "LinkedIn Profile",
  msgMessage: "Message",
  msgPassword: "Password",
  msgProfile: "Profile",


  msgSelect: "Select",
  msgReasonContact: "Reason for contact",
  msgProposeChallenge: "Propose a challenge idea",
  msgProposeResource: "Propose a design resource",
  msgProposeConcept: "Propose a concept and demo",
  msgProposeOther: "Other",

  msgMainImage: "Main image *",
  msgMainEntityImageText: "Recomended: JPEG or PNG 24 Bits (not transparent). Required: 500 x 500 px",

  msgRegisterSuccess: "Registered correctly",
  msgParticipateSuccess: "Your participation information was sending correctly",

  msgCompanyName: "Organization / Entity",
  msgCompanyUrl: "Company URL",
  msgServiceName: "Service name *",
  msgServiceUrl: "Service URL",
  msgServiceExplain: "Explain briefly what type of problem you are solving *",

  msgEmail: "E-mail",
  msgPhone: "Phone number",
  msgPhoneObl: "Phone number *",
  msgCountry: "Country/Region",
  msgSkills: "Skills, accomplishments, past work history *",

  msgTerms: "By clicking 'Submit' below, you consent to allow INTRIDE to store and process the personal information filled in above to provide you the requested service",

  msgParticipateHeader: "Participation form",
  msgParticipateTitle: "Now accepting applications for this Challenge",
  msgParticipateSubtitle: "Application deadline: Noon on 2020/6/11 (Thu)",

  msgParticipateNotes: "Notes",
  msgParticipateNoteOne: "Please answer each question of the challenge as concisely as possible.",
  msgParticipateNoteTwo: "Please note that the Applicant shall not provide highly confidential information, including but not limited to source code and technical information essential for the Applicant’s business.",
  msgParticipateNoteThree: "Please note that any inquiries regarding the screening process are not to be made.",
  msgParticipateNoteFour: "Please note that all costs relating to application shall be borne by the Applicant.",
  msgCompanyPage: "Company page",
  msgChallengeFaq: "FAQ",
  msgWebsite: "Website",

  msgParticipateFormCompanyTitle: "Organization / Entity information",
  msgParticipateFormCompanySubtitle: "Information relative to the organization (if exist)",
  msgParticipateFormRepresentativeTitle: "Representative information",
  msgParticipateFormRepresentativeSubtitle: "Information relative to the organization (if exist)",

  msgThreads: "Threads",
  msgOpen: "Open",
  msgClose: "Close",
  actionComment: "Comment",
  actionBack: "Back",

  errorOblData: "Please fill in all mandatory fields",
  errorSave: "Operation error, please check your fields",
  errorLogin: "Email/password incorrect",

  /* PROFILE TYPES */
  msgStudent: "University - Student",
  msgProfessor: "University - Professor",
  msgCompany: "Company",
  msgCenter: "Technological center",
  msgOther: "Other",

  /* TRAINING RESOURCES CATEGORY */
  msgTrainingCat1: "Design and Sustainability",
  msgTrainingCat2: "Economics, business model and circular economy",
  msgTrainingCat3: "Product and Service Design",
  msgTrainingCat4: "Final Project INTRIDE",
  

  /* COUNTRIES */
  msgAfghanistan: "Afghanistan",
  msgAlbania: "Albania",
  msgAlgeria: "Algeria",
  msgAmericanSamoa: "American Samoa",
  msgAndorra: "Andorra",
  msgAngola: "Angola",
  msgAnguilla: "Anguilla",
  msgAntarctica: "Antarctica",
  msgAntiguaandBarbuda: "Antigua and Barbuda",
  msgArgentina: "Argentina",
  msgArmenia: "Armenia",
  msgAruba: "Aruba",
  msgAustralia: "Australia",
  msgAustria: "Austria",
  msgAzerbaijan: "Azerbaijan",
  msgBahamas: "Bahamas",
  msgBahrain: "Bahrain",
  msgBangladesh: "Bangladesh",
  msgBarbados: "Barbados",
  msgBelarus: "Belarus",
  msgBelgium: "Belgium",
  msgBelize: "Belize",
  msgBenin: "Benin",
  msgBermuda: "Bermuda",
  msgBhutan: "Bhutan",
  msgBolivia: "Bolivia",
  msgBosniaHerzegovina: "Bosnia and Herzegovina",
  msgBotswana: "Botswana",
  msgBouvetIsland: "Bouvet Island",
  msgBrazil: "Brazil",
  msgBritishIndian: "British Indian Ocean Territory",
  msgBrunei: "Brunei",
  msgBulgaria: "Bulgaria",
  msgBurkinaFaso: "Burkina Faso",
  msgBurundi: "Burundi",
  msgCambodia: "Cambodia",
  msgCameroon: "Cameroon",
  msgCanada: "Canada",
  msgCapeVerde: "Cape Verde",
  msgCaymanIslands: "Cayman Islands",
  msgCentralAfrican: "Central African Republic",
  msgChad: "Chad",
  msgChile: "Chile",
  msgChina: "China",
  msgChristmasIsland: "Christmas Island",
  msgCocosIslands: "Cocos (Keeling) Islands",
  msgColombia: "Colombia",
  msgComoros: "Comoros",
  msgCongo: "Congo",
  msgRepublicCongo: "The Democratic Republic of Congo",
  msgCookIslands: "Cook Islands",
  msgCostaRica: "Costa Rica",
  msgIvoryCoast: "Ivory Coast",
  msgCroatia: "Croatia",
  msgCuba: "Cuba",
  msgCyprus: "Cyprus",
  msgCzechRepublic: "Czech Republic",
  msgDenmark: "Denmark",
  msgDjibouti: "Djibouti",
  msgDominica: "Dominica",
  msgDominicanRepublic: "Dominican Republic",
  msgEastTimor: "East Timor",
  msgEcuador: "Ecuador",
  msgEgypt: "Egypt",
  msgEngland: "England",
  msgElSalvador: "El Salvador",
  msgEquatorialGuinea: "Equatorial Guinea",
  msgEritrea: "Eritrea",
  msgEstonia: "Estonia",
  msgEthiopia: "Ethiopia",
  msgFalklandIslands: "Falkland Islands",
  msgFaroeIslands: "Faroe Islands",
  msgFijiIslands: "Fiji Islands",
  msgFinland: "Finland",
  msgFrance: "France",
  msgFrenchGuiana: "French Guiana",
  msgFrenchPolynesia: "French Polynesia",
  msgFrenchSouthern: "French Southern territories",
  msgGabon: "Gabon",
  msgGambia: "Gambia",
  msgGeorgia: "Georgia",
  msgGermany: "Germany",
  msgGhana: "Ghana",
  msgGibraltar: "Gibraltar",
  msgGreece: "Greece",
  msgGreenland: "Greenland",
  msgGrenada: "Grenada",
  msgGuadeloupe: "Guadeloupe",
  msgGuam: "Guam",
  msgGuatemala: "Guatemala",
  msgGuernsey: "Guernsey",
  msgGuinea: "Guinea",
  msgGuineaBissau: "Guinea-Bissau",
  msgGuyana: "Guyana",
  msgHaiti: "Haiti",
  msgHeardMcDonald: "Heard Island and McDonald Islands",
  msgHolySee: "Holy See (Vatican City State)",
  msgHonduras: "Honduras",
  msgHongKong: "Hong Kong",
  msgHungary: "Hungary",
  msgIceland: "Iceland",
  msgIndia: "India",
  msgIndonesia: "Indonesia",
  msgIran: "Iran",
  msgIraq: "Iraq",
  msgIreland: "Ireland",
  msgIsrael: "Israel",
  msgIsleMan: "Isle of Man",
  msgItaly: "Italy",
  msgJamaica: "Jamaica",
  msgJapan: "Japan",
  msgJersey: "Jersey",
  msgJordan: "Jordan",
  msgKazakhstan: "Kazakhstan",
  msgKenya: "Kenya",
  msgKiribati: "Kiribati",
  msgKuwait: "Kuwait",
  msgKyrgyzstan: "Kyrgyzstan",
  msgLaos: "Laos",
  msgLatvia: "Latvia",
  msgLebanon: "Lebanon",
  msgLesotho: "Lesotho",
  msgLiberia: "Liberia",
  msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
  msgLiechtenstein: "Liechtenstein",
  msgLithuania: "Lithuania",
  msgLuxembourg: "Luxembourg",
  msgMacao: "Macao",
  msgNorthMacedonia: "North Macedonia",
  msgMadagascar: "Madagascar",
  msgMalawi: "Malawi",
  msgMalaysia: "Malaysia",
  msgMaldives: "Maldives",
  msgMali: "Mali",
  msgMalta: "Malta",
  msgMarshallIslands: "Marshall Islands",
  msgMartinique: "Martinique",
  msgMauritania: "Mauritania",
  msgMauritius: "Mauritius",
  msgMayotte: "Mayotte",
  msgMexico: "Mexico",
  msgMicronesia: "Micronesia, Federated States of",
  msgMoldova: "Moldova",
  msgMonaco: "Monaco",
  msgMongolia: "Mongolia",
  msgMontserrat: "Montserrat",
  msgMontenegro: "Montenegro",
  msgMorocco: "Morocco",
  msgMozambique: "Mozambique",
  msgMyanmar: "Myanmar",
  msgNamibia: "Namibia",
  msgNauru: "Nauru",
  msgNepal: "Nepal",
  msgNetherlands: "Netherlands",
  msgNetherlandsAntilles: "Netherlands Antilles",
  msgNewCaledonia: "New Caledonia",
  msgNewZealand: "New Zealand",
  msgNicaragua: "Nicaragua",
  msgNiger: "Niger",
  msgNigeria: "Nigeria",
  msgNiue: "Niue",
  msgNorfolkIsland: "Norfolk Island",
  msgNorthKorea: "North Korea",
  msgNorthernIreland: "Northern Ireland",
  msgNorthernMariana: "Northern Mariana Islands",
  msgNorway: "Norway",
  msgOman: "Oman",
  msgPakistan: "Pakistan",
  msgPalau: "Palau",
  msgPalestine: "Palestine",
  msgPanama: "Panama",
  msgPapua: "Papua New Guinea",
  msgParaguay: "Paraguay",
  msgPeru: "Peru",
  msgPhilippines: "Philippines",
  msgPitcairn: "Pitcairn",
  msgPoland: "Poland",
  msgPortugal: "Portugal",
  msgPuertoRico: "Puerto Rico",
  msgQatar: "Qatar",
  msgReunion: "Reunion",
  msgRomania: "Romania",
  msgRussianFederation: "Russian Federation",
  msgRwanda: "Rwanda",
  msgSaintHelena: "Saint Helena",
  msgSaintKitts: "Saint Kitts and Nevis",
  msgSaintLucia: "Saint Lucia",
  msgSaintPierre: "Saint Pierre and Miquelon",
  msgSaintVincent: "Saint Vincent and the Grenadines",
  msgSamoa: "Samoa",
  msgSanMarino: "San Marino",
  msgSaoTome: "Sao Tome and Principe",
  msgSaudiArabia: "Saudi Arabia",
  msgScotland: "Scotland",
  msgSenegal: "Senegal",
  msgSerbia: "Serbia",
  msgSeychelles: "Seychelles",
  msgSierraLeone: "Sierra Leone",
  msgSingapore: "Singapore",
  msgSlovakia: "Slovakia",
  msgSlovenia: "Slovenia",
  msgSolomonIslands: "Solomon Islands",
  msgSomalia: "Somalia",
  msgSouthAfrica: "South Africa",
  msgSouthSandwich: "South Georgia and the South Sandwich Islands",
  msgSouthKorea: "South Korea",
  msgSouthSudan: "South Sudan",
  msgSpain: "Spain",
  msgSriLanka: "SriLanka",
  msgSudan: "Sudan",
  msgSuriname: "Suriname",
  msgSvalbardJan: "Svalbard and Jan Mayen",
  msgSwaziland: "Swaziland",
  msgSweden: "Sweden",
  msgSwitzerland: "Switzerland",
  msgSyria: "Syria",
  msgTajikistan: "Tajikistan",
  msgTanzania: "Tanzania",
  msgThailand: "Thailand",
  msgTimorLeste: "Timor-Leste",
  msgTogo: "Togo",
  msgTokelau: "Tokelau",
  msgTonga: "Tonga",
  msgTrinidadTobago: "Trinidad and Tobago",
  msgTunisia: "Tunisia",
  msgTurkey: "Turkey",
  msgTurkmenistan: "Turkmenistan",
  msgTurksCaicos: "Turks and Caicos Islands",
  msgTuvalu: "Tuvalu",
  msgUganda: "Uganda",
  msgUkraine: "Ukraine",
  msgUnitedArabEmirates: "United Arab Emirates",
  msgUnitedKingdom: "United Kingdom",
  msgUnitedStates: "United States",
  msgUnitedStatesMinor: "United States Minor Outlying Islands",
  msgUruguay: "Uruguay",
  msgUzbekistan: "Uzbekistan",
  msgVanuatu: "Vanuatu",
  msgVenezuela: "Venezuela",
  msgVietnam: "Vietnam",
  msgVirginIslandsBritish: "Virgin Islands, British",
  msgVirginIslandsUS: "Virgin Islands, U.S.",
  msgWales: "Wales",
  msgWallisFutuna: "Wallis and Futuna",
  msgWesternSahara: "Western Sahara",
  msgYemen: "Yemen",
  msgZambia: "Zambia",
  msgZimbabwe: "Zimbabwe",
};

export default es;