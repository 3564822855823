import React, { useState, useEffect } from 'react';
import { Button, Container, ContainerFlex, TextLocalized } from '../../styled/Styled';
import img_home from '../../../images/img/background-home.jpg';
import { HttpConnection, HttpOperations } from '../../util/HttpConnection';
import TrainingResourceView from './TrainingResourceView';

const SectionFilter = ({ item, currentTab, setCurrentTab }) => {
    return <Button pv="16" mx="8" ph="32"
        color={currentTab == item.id ? "colorblack" : "colorwhite"}
        fontColor={currentTab != item.id ? "colorblack" : "colorwhite"}
        onclick={() => setCurrentTab(item.id)} children={<TextLocalized children={item.name} />} />
}

const TrainingResourceFilterView = () => {
    const [items, setItems] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);

    const catgories = [
        { id: 1, name: "msgTrainingCat1" },
        { id: 2, name: "msgTrainingCat2" },
        { id: 3, name: "msgTrainingCat3" },
        { id: 4, name: "msgTrainingCat4" },
    ]

    useEffect(() => {
        HttpConnection(HttpOperations.trainings,
            item => setItems(item),
            error => console.log(error));
    }, [])

    return (
        <Container color="colorgray" extras={"full-height"}>
            <Container width="12" image={img_home}>
                <ContainerFlex width="12" color="colorgrayalpha" pv="100" justify="center" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                    <Container width="12" children={<TextLocalized children="msgCat5" />} />
                    <ContainerFlex width="8" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgCat5Sub" />} />
                </ContainerFlex>
            </Container>

            <ContainerFlex width="12" pv="16" justify="center" color="colorwhite">
                {
                    catgories.map((item) => <SectionFilter key={item.id} item={item} currentTab={currentTab} setCurrentTab={setCurrentTab} />)
                }

            </ContainerFlex>
            <Container width="12" minheight="1px" color="colorgraydark" />

            <ContainerFlex width="12" justify="center">
                <TrainingResourceView items={items.filter(item => item.training_category_id == currentTab)} />
            </ContainerFlex>
        </Container>
    );
};

export default TrainingResourceFilterView;