import React from 'react';
import { ContainerFlex } from '../styled/Styled';
import { useHistory } from 'react-router-dom';
import ic_logo from '../../images/img/intride_challenge_logo.png'
import MenuView from './MenuView';

const Header = () => {
    const history = useHistory();

    return (
        <ContainerFlex color="colorprimarydark" ph="32" pv="4" justify="space-between" align="center">
            <img onClick={() => history.push('')} style={{ cursor: "pointer", padding: "16px 0" }} height="70" alt="logo" src={ic_logo} />
            <MenuView index="0" />
        </ContainerFlex>
    );
};

export default Header;