import React, { useContext, useEffect, useState } from 'react';
import { ContainerFlex, Container, TextLocalized, Card, Button } from '../../styled/Styled';
import { LanguageContext } from '../../providers/LanguageProvider';
import { participateDefault } from '../../util/ConstantUtil';
import { notificationDefault, NotificationView } from '../../util/NotificationUtil';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { useParams } from 'react-router-dom';
import { UserAction, UserContext } from '../../providers/UserProvider';

const InputWrapper = ({ children }) => <Card px="8" mv="16" width="12" children={children} />

const ParticipateView = () => {

    const [item, setItem] = useState();
    const [formData, setFormData] = useState({ ...participateDefault });
    const [notification, setNotification] = useState({ ...notificationDefault });

    const { dispatch } = useContext(UserContext);
    const languageContext = useContext(LanguageContext);
    const _changeText = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    let params = useParams();

    useEffect(() => {
        HttpConnection(`${HttpOperations.challenges}/${params.id}`,
            item => setItem(item),
            error => console.log(error));
    }, [params.id])

    const sendForm = () => {
        if (formData.contact_email !== "" && formData.solution !== "") {
            HttpConnection(`${HttpOperations.participate}/${params.id}`,
                response => {
                    setFormData({ ...participateDefault });
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorgreen", message: "msgParticipateSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorred", message: "errorSave" } }),
                HttpMethod.post, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorred", message: "errorOblData" } });
        }
    }

    return item ? (
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="12" lg="8" justify="center">
                <Container width="12" textAlign="center" px="32" fontWeight="bold" fontSize="37px" children={<TextLocalized children="msgParticipateHeader" />} />
                <Container width="12" lg="6" px="32">
                    <Container fontWeight="bold" fontSize="32px" mb="32" children={<TextLocalized children="msgParticipateTitle" />} />
                    <Container fontWeight="300" fontSize="22px" mb="32" children={item.title} />

                    <ContainerFlex mb="16" color="colorwhite" fontWeight="bold" children={<TextLocalized children="msgParticipateNotes" />} />
                    <Card shadow={false} width="12" px="32" justify="center" align="center" border="solid" borderWidth="1px" borderColor="colorprimary">
                        <ul>
                            <li style={{ margin: '8px 0' }}><TextLocalized children='msgParticipateNoteOne' /></li>
                            <li style={{ margin: '8px 0' }}><TextLocalized children='msgParticipateNoteTwo' /></li>
                            <li style={{ margin: '8px 0' }}><TextLocalized children='msgParticipateNoteThree' /></li>
                            <li style={{ margin: '8px 0' }}><TextLocalized children='msgParticipateNoteFour' /></li>
                        </ul>
                    </Card>

                    <ContainerFlex display="none" width="12" justify="space-between" align="center">
                        <Button shadow={false} mx="16" ph="64" pv="16" color="colorprimarylight" radius="30px"
                            fontSize="17px" fontWeight="normal" fontColor="colorwhite" textAlign="center"
                            children={<TextLocalized children="msgCompanyPage" />} />

                        <Button shadow={false} mx="16" ph="64" pv="16" color="colorprimary" radius="30px"
                            fontSize="17px" fontWeight="normal" fontColor="colorwhite"
                            textAlign="center" children={<TextLocalized children="msgChallengeFaq" />} />
                    </ContainerFlex>
                </Container>

                <ContainerFlex width="12" lg="6" px="32">
                    <Container fontWeight="bold" fontSize="21px" children={<TextLocalized children="msgParticipateFormCompanyTitle" />} />
                    <Container mv="16" children={<TextLocalized children="msgParticipateFormCompanySubtitle" />} />

                    <InputWrapper children={<input name="company_name" value={formData.company_name} onChange={_changeText} placeholder={languageContext.dictionary["msgCompanyName"]} />} />
                    <InputWrapper children={<input name="company_web" value={formData.company_web} onChange={_changeText} placeholder={languageContext.dictionary["msgWebsite"]} />} />

                    <Container mt="32" fontWeight="bold" fontSize="21px" children={<TextLocalized children="msgParticipateFormRepresentativeTitle" />} />
                    <Container mv="16" children={<TextLocalized children="msgParticipateFormRepresentativeSubtitle" />} />

                    <InputWrapper children={<input name="contact_firstname" value={formData.contact_firstname} onChange={_changeText} placeholder={languageContext.dictionary["msgFirstname"]} />} />
                    <InputWrapper children={<input name="contact_lastname" value={formData.contact_lastname} onChange={_changeText} placeholder={languageContext.dictionary["msgLastname"]} />} />
                    <InputWrapper children={<input name="contact_email" value={formData.contact_email} onChange={_changeText} placeholder={languageContext.dictionary["msgEmailObl"]} />} />
                    <InputWrapper children={<input name="contact_phone" value={formData.contact_phone} onChange={_changeText} placeholder={languageContext.dictionary["msgPhone"]} />} />
                    <InputWrapper children={<input name="company_job" value={formData.company_job} onChange={_changeText} placeholder={languageContext.dictionary["msgJobTitle"]} />} />
                    <InputWrapper children={<input name="contact_job" value={formData.contact_job} onChange={_changeText} placeholder={languageContext.dictionary["msgLinkdin"]} />} />
                    <InputWrapper children={<textarea name="solution" value={formData.solution} onChange={_changeText} placeholder={languageContext.dictionary["msgServiceExplain"]} rows="8" />} />

                    <Container width="12" mv="16" textAlign="center" children={<TextLocalized children="msgTerms" />} />

                    <ContainerFlex width="12" justify="center" align="center">
                        <Button onclick={sendForm} shadow={false} mx="16" ph="64" pv="16" color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal" fontColor="colorwhite" textAlign="center" children={<TextLocalized children="actionSend" />} />
                    </ContainerFlex>
                </ContainerFlex>

                <NotificationView noti={notification} setNoti={setNotification} />
            </ContainerFlex>

        </ContainerFlex >
    ) : <Container minheight="calc(100vh - 106px)" />;
};

export default ParticipateView;