import React, { useEffect, useState } from 'react';
import { Container, ContainerFlex, TextLocalized, Button, Card } from '../../styled/Styled';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { HttpConnection, HttpOperations, imageURL } from '../../util/HttpConnection';

const RoundLeftImg = styled.img`
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
`;

const PrototipaLabItemView = ({ item }) => {
    return <ContainerFlex width="8" px="16">
        <Card flex={true} shadow={false} align="stretch" width="12" border="solid" borderWidth="1px" borderColor="colorgraydark" color="colorwhite" cursor="pointer">

            <Container width="3" height="300px">
                <RoundLeftImg src={`${imageURL}${item.image}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
            </Container>

            <Container width="9" ph="16">
                <Container fontWeight="bold" fontSize="46px" mt="16" children={item.title} />
                <Container fontSize="12px" fontColor="colorgrayalpha" children="26/07/2020" />
                <Container fontSize="14px" maxheight="100%" mt="16" children={item.short_description} />
                <ContainerFlex mv="16" justify="space-between" align="stretch">
                    <ContainerFlex alignContent="center" justify="center" fontWeight="bold" fontSize="14px" fontColor="colorgrayalpha" children={item.author} />
                </ContainerFlex>
            </Container>
        </Card>
    </ContainerFlex>
}

const PrototipaLabDetailView = () => {
    const [item, setItem] = useState({})
    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        const requestAPI = async () => {
            HttpConnection(`${HttpOperations.prototipaLabs}/${params.id}`,
                item => setItem(item),
                error => console.log(error));
        }
        requestAPI();
    }, [params.id])

    return (
        <Container with="12">
            <Container width="12" image={`${imageURL}${item.image}`}>
                <ContainerFlex width="12" color="colorgrayalpha" pv="50" justify="center" extras="blur">
                    <PrototipaLabItemView item={item} />
                </ContainerFlex>
            </Container>

            <ContainerFlex width="12" justify="center" color="colorwhite" pv="120">
                <Container width="6">
                    <Container width="12" textAlign="center" ph="32" fontColor="colorprimary" fontWeight="bold" fontSize="32px" children="SUMMARY" />
                    <Container mv="30" children={item.full_description} />
                </Container>
            </ContainerFlex>

            {
                item && item.gallery && item.gallery.length > 0 ?
                    <ContainerFlex width="12" justify="center" align="center" textAlign="center" color="colorgray" pv="120">
                        <Container width="8">
                            <Container width="12" textAlign="center" ph="32" fontColor="colorprimary" fontWeight="bold" fontSize="32px" children="GALLERY" />

                            <ContainerFlex mv="30">
                                {
                                    item.gallery.map((image, index) => <Container key={index} width="6" px="16" children={<Card cursor="pointer" image={`${imageURL}${image.url}`} width="12" minheight="300px" />} />)
                                }
                            </ContainerFlex>
                        </Container>
                    </ContainerFlex>
                    : null
            }

            <Container width="12" pv="60">
                <Container width="12" fontSize="28px" fontWeight="bold" fontColor="colorprimary" textAlign="center">
                    <TextLocalized children="msgProtipaSecThreeTitle" />
                </Container>
                <Container width="12" textAlign="center" mt="16">
                    <TextLocalized children="msgHomeScThreeSubtitle" />
                </Container>

                <ContainerFlex width="12" justify="center" align="center" mt="32">
                    <Button onclick={() => history.push("contact")} shadow={false} mx="16" ph="64" pv="16" color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal" fontColor="colorwhite" textAlign="center">
                        <TextLocalized children="msgProtipaSecThreeButton" />
                    </Button>
                </ContainerFlex>
            </Container>
        </Container>
    );
};

export default PrototipaLabDetailView;