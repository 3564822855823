import React, { useState, Fragment, useEffect } from 'react';
import { Container, ContainerFlex, TextLocalized, Card } from '../../styled/Styled';
import ChallengeView from './ChallengeView';
import img_home from '../../../images/img/background-home.jpg';
import { countries, filterDefault } from '../../util/ConstantUtil';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';

const FilterBody = ({ formData, _onchangeFrom }) => {
    return <Fragment>
        <Card color="colorwhite" px="8" mh="8" fontColor="colorgrayLight" fontSize="18px">
            <select name="topic" style={{ width: "100%" }} value={formData.status} onChange={_onchangeFrom} >
                <option value="">Topic</option>
                <option value="1">Design</option>
                <option value="2">Digital</option>
                <option value="3">Green</option>
                <option value="4">Soft</option>
            </select>
        </Card>

        <Card color="colorwhite" px="8" mh="8" fontColor="colorgrayLight" fontSize="18px">
            <select name="audience" style={{ width: "100%" }} value={formData.status} onChange={_onchangeFrom} >
                <option value="">Audience</option>
                <option value="2">Designers</option>
                <option value="5">Researchers</option>
                <option value="1">Students</option>
                <option value="3">Entrepreneurs</option>
                <option value="4">Employees</option>
                <option value="6">Stakeholders</option>
            </select>
        </Card>

        <Card color="colorwhite" px="8" mh="8" fontColor="colorgrayLight" fontSize="18px">
            <select name="typology" style={{ width: "100%" }} value={formData.status} onChange={_onchangeFrom} >
                <option value="">Typology</option>
                <option value="1">One-day challenge</option>
                <option value="2">Challenge</option>
                <option value="3">Workshop</option>
                <option value="5">Competition</option>
                <option value="6">Funding Opportunity</option>
            </select>
        </Card>

        <Card color="colorwhite" px="8" mh="8" fontColor="colorgrayLight" fontSize="18px">
            <select name="team" style={{ width: "100%" }} value={formData.status} onChange={_onchangeFrom} >
                <option value="">Team</option>
                <option value="1">Individual</option>
                <option value="2">2 people</option>
                <option value="3">3 people</option>
                <option value="4">Group</option>
            </select>
        </Card>

        <Card color="colorwhite" px="8" mh="8" fontColor="colorgrayLight" fontSize="18px">
            <select name="state" style={{ width: "100%" }} value={formData.status} onChange={_onchangeFrom} >
                <option value="">Country</option>
                {countries.map((country, index) => <option key={index} value={country.country}>{country.country}</option>)}
            </select>
        </Card>

        <Card color="colorwhite" px="8" mh="8" fontColor="colorgrayLight" fontSize="18px">
            <select name="state" style={{ width: "100%" }} value={formData.status} onChange={_onchangeFrom} >
                <option value="">Sort by</option>
            </select>
        </Card>

    </Fragment>
}

const ChallengeFilterView = () => {
    const [challenges, setChallenges] = useState([]);
    const [formData, setFormData] = useState({ ...filterDefault });

    const _onchangeFrom = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    useEffect(() => {
        HttpConnection(HttpOperations.challenges,
            item => setChallenges(item),
            error => console.log(error), HttpMethod.post, formData);
    }, [formData])

    return (
        <Container extras={"full-height"}>
            <Container width="12" image={img_home}>

                <ContainerFlex width="12" color="colorgrayalpha" pv="100" justify="center" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                    <Container width="12" children={<TextLocalized children="msgCat1" />} />
                    <ContainerFlex width="8" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgCat1Sub" />} />
                </ContainerFlex>

            </Container>

            <Container>
                <ContainerFlex width="12" fontColor="colorwhite" color="colorgray" justify="center" align="center" pv="16">
                    <Container ph="16" fontColor="colorblack" fontSize="15px" fontWeight="bold" children={<TextLocalized children="msgFilterBy" />} />
                    <FilterBody formData={formData} _onchangeFrom={_onchangeFrom} />
                </ContainerFlex>
                <Container width="12" minheight="1px" color="colorgraydark" />
            </Container>

            <ContainerFlex width="12" justify="center">
                <ChallengeView items={challenges} />
            </ContainerFlex>

        </Container>
    );
};

export default ChallengeFilterView;