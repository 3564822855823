import React from 'react';
import { Card, Container } from '../../styled/Styled';

const MessageItem = ({item}) => {
    return (
        <Card width="12" mv="8" border="solid" color="colorwhite" borderColor="colorbluelight" borderWidth="1px">
            <Card px="16" shadow={false} fontSize="14px" radiusbl="0" radiusbr="0" color="colorbluelight-a50" children={`${item.owner.name} ${item.owner.lastname} commented`} />
            <Container width="12" height="1px" color="colorgray" />
            <Container px="16" children={item.text} />
        </Card>
    );
};

export default MessageItem;