import React, { useContext, useRef, useState } from "react"
import styled from 'styled-components'
import { LanguageContext } from "../providers/LanguageProvider";
import Icon from '@mdi/react'
import { mdiDelete } from '@mdi/js'

/* UTIL */
const widthResponsive = (attr) => {
    const { width, xs, sm, lg, xl } = attr;
    const mwidth = `${width ? width === "wrap" ? " col" : `col-${width}` : ``}${xs ? xs === "wrap" ? " col-xs" : ` col-xs-${xs}` : ``}${sm ? sm === "wrap" ? " col-sm" : ` col-sm-${sm}` : ``}${lg ? lg === "wrap" ? " col-lg" : ` col-lg-${lg}` : ``}${xl ? xl === "wrap" ? " col-xl" : ` col-xl-${xl}` : ``}`;
    return mwidth;
}

const marginAndPadding = (props) => {
    const { px = 0, pt, pr, pb, pl, ph, pv } = props;
    const { mx = 0, mt, mr, mb, ml, mh, mv } = props;
    const padding = `${pt ? pt : pv ? pv : px}px ${pr ? pr : ph ? ph : px}px ${pb ? pb : pv ? pv : px}px ${pl ? pl : ph ? ph : px}px`;
    const margin = `${mt ? mt : mv ? mv : mx}px ${mr ? mr : mh ? mh : mx}px ${mb ? mb : mv ? mv : mx}px ${ml ? ml : mh ? mh : mx}px`;

    return `
        padding: ${padding};
        margin: ${margin};
    `;
}

const textFormat = (props) => {
    const { fontColor, fontWeight } = props;
    const { fontSize, textAlign } = props;

    return `
        ${fontColor ? `color: var(--${fontColor});` : ``}
        ${fontWeight ? `font-weight: ${fontWeight};` : ``}
        ${fontSize ? `font-size: ${fontSize};` : ``}
        ${textAlign ? `text-align: ${textAlign};` : ``}

    `;
}

const displayItem = (props) => {
    const { display = "block", displaySm, displayMd, displayLg, displayXl } = props;
    return `
        display: ${display};

        @media (min-width: 576px) {
            display: ${displaySm ?? display};
        }

        @media (min-width: 768px) {
            display: ${displayMd ?? display};
        }

        @media (min-width: 992px) {
            display: ${displayLg ?? display};
        }

        @media (min-width: 1200px) {
            display: ${displayXl ?? display};
        }
    `
}

/* COMPONENT CONTAINER */
const FmuiContainer = styled.div`
    ${props => {
        //Set default values
        const { position = 'initial', cursor = "inherit", top, rigth, bottom, left } = props.attr;
        const { height, minwidth, maxwidth, minheight, maxheight, overflowY = "initial" } = props.attr;
        const { color, colorEnd } = props.attr;
        const { elevation, opacity = 1 } = props.attr;
        const { image, imgWidth, imgHeight, imgMode = "cover", imgPosition = "center" } = props.attr;

        return `
        position: ${position};
        cursor: ${cursor};
        ${top ? `top: ${top};` : ``}
        ${rigth ? `rigth: ${rigth};` : ``}
        ${bottom ? `bottom: ${bottom};` : ``}
        ${left ? `left: ${left};` : ``}

        ${minwidth ? `min-width: ${minwidth};` : ``}
        ${minheight ? `min-height: ${minheight};` : ``}
        ${maxwidth ? `max-width: ${maxwidth};` : ``}
        ${maxheight ? `max-height: ${maxheight};` : ``}
        ${height ? `height: ${height};` : ``}

        ${marginAndPadding(props.attr)}
        background: ${colorEnd ? `linear-gradient(var(--${color}), var(--${colorEnd}));` : `var(--${color})`};
        ${image ? `background-image: url(${image}); background-size: ${imgWidth ? `${imgWidth} ${imgHeight}` : imgMode}; background-position: ${imgPosition};` : ""}
        box-sizing: border-box;
        overflow-y: ${overflowY};
        z-index: ${elevation ? elevation : 0};
        opacity: ${opacity};

        ${textFormat(props.attr)}
        ${displayItem(props.attr)}
    `;
    }}
`;

export const Container = (attr) => {
    const { extras, children } = attr;
    const { onclick, myRef } = attr;
    const mwidth = widthResponsive(attr);
    return (
        <FmuiContainer
            onClick={onclick}
            ref={myRef}
            attr={attr}
            className={`${mwidth}${extras ? ` ${extras}` : ""}`}
            children={children} />
    );
}

/* COMPONENT CONTAINER-FLEX */
const FmuiContainerFlex = styled(FmuiContainer)`
    ${props => {
        //Set defaults values
        const { multiline = true } = props.attr;
        const {
            direction = "row",
            justify = "flex-start",
            align = "flex-start",
            alignContent = "auto" } = props.attr;

        const { display = "flex", displaySm, displayMd, displayLg, displayXl } = props.attr;

        const { flexMode = "" } = props.attr;

        return `
        flex-wrap: ${multiline ? "wrap" : "nowrap"};
        flex-direction: ${direction};
        justify-content: ${justify};
        align-items: ${align};
        align-content: ${alignContent};

        display: ${display};

        ${flexMode === "" ? "" : "flex: 1;"}

        @media (min-width: 576px) {
            display: ${displaySm ?? display};
        }

        @media (min-width: 768px) {
            display: ${displayMd ?? display};
        }

        @media (min-width: 992px) {
            display: ${displayLg ?? display};
        }

        @media (min-width: 1200px) {
            display: ${displayXl ?? display} ;
        }
    `;
    }}
`;

export const ContainerFlex = (attr) => {
    const { extras, children } = attr;
    const { onclick, myRef } = attr;
    const mwidth = widthResponsive(attr);
    return (
        <FmuiContainerFlex
            onClick={onclick}
            ref={myRef}
            attr={attr}
            className={`${mwidth}${extras ? ` ${extras}` : ""}`}
            children={children} />
    );
}

export const HoverContainer = styled.div`
    & {
        width: 100%;
        height: 100%;
        display:flex;
        justify-content:center;
        align-items: center;
        opacity: 0;
    }

    &:hover {
        opacity: 1;
        background-color: var(--colorgrayalpha);
        cursor: pointer;
        border-radius: 6px;
    }
`

/* COMPONENT CARD */
const FmuiCard = styled(FmuiContainer)`
    ${props => cardProps(props)}
`;

const FmuiCardFlex = styled(FmuiContainerFlex)`
    ${props => cardProps(props)}
`;

const cardProps = (props) => {
    const { radius = "6px", radiustl, radiustr, radiusbr, radiusbl } = props.attr;
    const { border = "none", borderWidth, borderColor } = props.attr;
    const { shadow = true, shVertical = false } = props.attr;

    return `
            ${border !== 'none' ? `border: ${borderWidth} ${border} var(--${borderColor})` : ''};
            border-top-left-radius: ${radiustl ? radiustl : radius};
            border-top-right-radius: ${radiustr ? radiustr : radius};
            border-bottom-right-radius: ${radiusbr ? radiusbr : radius};
            border-bottom-left-radius: ${radiusbl ? radiusbl : radius};
            ${shadow ? shVertical ? `box-shadow:0 10px 10px -10px rgba(0, 0, 0, 0.5);` : `box-shadow: 0 0 0 1px rgba(205,210,223,0.15),0 1px 4px 0 rgba(0,0,0,0.3);` : ``}
        `;

}

export const Card = (attr) => {
    const { children, flex = false } = attr;
    const { onclick, myRef } = attr;
    const mwidth = widthResponsive(attr);
    return flex
        ? <FmuiCardFlex onClick={onclick} ref={myRef} attr={attr} className={mwidth} children={children} />
        : <FmuiCard onClick={onclick} ref={myRef} attr={attr} className={mwidth} children={children} />;
}

/* COMPONENT BUTTON */
const FmuiButton = styled(FmuiCard)`
    ${props => buttonProps(props)}
`;

const FmuiButtonFlex = styled(FmuiCardFlex)`
    ${props => buttonProps(props)}
`;

const buttonProps = (attr) => `
    cursor: pointer;
    overflow-y:hidden;
    & > label {
        cursor: pointer;
    }
`;

export const Button = (attr) => {
    const { onclick, children, flex = false } = attr;
    const mwidth = widthResponsive(attr);
    return (
        flex
            ? <FmuiButtonFlex attr={attr} onClick={onclick} className={`${mwidth}`} children={children} />
            : <FmuiButton attr={attr} onClick={onclick} className={`${mwidth}`} children={children} />
    );
}

export const TextLocalized = (attr) => {
    const languageContext = useContext(LanguageContext);
    const { children } = attr;

    const text = languageContext.dictionary[children];

    return <label dangerouslySetInnerHTML={{ __html: text }} />
}

export const TextHtml = ({ children }) => <label dangerouslySetInnerHTML={{ __html: children }} />


export const InputImage = (props) => {
    const { title, recomended, align = "center" } = props;
    const { multiple = false } = props;
    const { imagePreviews = [], setImagePreviews, maxImages = 1 } = props;
    const [errorSize, setErrorSize] = useState(false);

    //Image
    const refInputFile = useRef(null);

    const _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var image = new Image();
            image.src = URL.createObjectURL(file);

            image.onload = () => {
                var width = image.naturalWidth,
                    height = image.naturalHeight;

                console.log(width)
                console.log(height)

                URL.revokeObjectURL(image.src);

                if (height && width) {
                    setImagePreviews([...imagePreviews, { file: file, url: reader.result }]);
                    setErrorSize(false);
                } else {
                    setErrorSize(true);
                }
            };
        }

        if (file !== null)
            reader.readAsDataURL(file)
    }

    const getImages = () => {
        let pictures = [];
        imagePreviews.forEach((img, index) => pictures.push(
            <Container key={index} height="200px" width={multiple ? "6" : "12"} maxwidth="200px" px="8" justify="center">
                <Container height="100%" width="12" position="relative" image={img.url}>
                    <Button onclick={() => setImagePreviews(imagePreviews.filter((item, ind) => index !== ind))} flex={true} justify="center" align="center" color="colorprimary" fontColor="colorwhite" position="absolute" top="8px" right="8px" px="8" radius="50%" children={<Icon path={mdiDelete} size="16px" />} />
                </Container>
            </Container>
        ));
        return pictures;
    }

    return <ContainerFlex width="12" pv="16" align={align} justify="center" textAlign="center">
        <Container width="12" fontSize="14px" fontWeight="400" children={<TextLocalized children={title} />} />
        <ContainerFlex width="12">
            <Card flex={true} shadow={false} width="12" justify="center" border="solid" borderWidth="1px" borderColor={errorSize ? "red" : "colorBackground"} px="8">
                {getImages()}
                <input ref={refInputFile} type="file" onChange={_handleImageChange} hidden />
                {
                    (imagePreviews.length < maxImages) ?
                        <Container width="12">
                            <Button width="auto" flex={true} justify="center" onclick={() => refInputFile.current.click()}
                                px="12" mx="8" color="colorprimary" fontColor="colorwhite"
                                fontSize="13px" fontWeight="bold" children={<TextLocalized children="msgSelect" />} />
                        </Container>
                        : null
                }
            </Card>
            <Container width="12" pv="16" fontSize="10px" fontWeight={errorSize ? "bold" : "400"} fontColor={errorSize ? "red" : "white"} children={<TextLocalized children={recomended} />} />
        </ContainerFlex>
    </ContainerFlex>
}

const TooltipStyled = styled.div`
${props => {
        return `
    position: relative;
display: inline-block;
cursor: pointer;

& .tooltiptext {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 5px;
    border-radius: 6px;
    transform: translateX(-50%);
    font-size: 12px;

    position: absolute;
    z-index: 1;
    bottom: ${props.bottom};
    left: ${props.left};
    white-space: nowrap;

    opacity: 0;
    transition: opacity 0.3s;
}

&:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
    `
    }

    };
`;

export const Tooltip = ({ left = "135%", bottom = "20%", text, children }) => {
    const languageContext = useContext(LanguageContext);

    return (
        <TooltipStyled left={left} bottom={bottom}>
            {children}
            <span className="tooltiptext">{languageContext.dictionary[text]}</span>
        </TooltipStyled>
    );
}
